import genderTypes from "../values/genderTypes";

export const BeneficiaryFilterFields = [
  {
   
    label: "Project",
    placeholder: "Select Project",
    name: "Project.Id",
    inputType: "async-dropdown",
    url: "/project/GetAll?DisablePagination=true",
    searchAttrib: "title",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
  },

  {
   
    label: "Select Sub Activity",
    placeholder: "Select Sub Activity",
    name: "SubActivity.Id",
    inputType: "async-dropdown",
    url: "/SubActivity/GetSubActivitiesByProject",
    searchAttrib: "name",
    id: "subActivityId",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "Project.Id",
      paramField: "ProjectId",
      action: "useValue",
    },
  },
  
  {
    label: "Gender",
    placeholder: "Select Gender",
    name: "Gender",
    id: "Gender",
    inputType: "async-dropdown",
    options: genderTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    inSearch: true,
    isEnum: true,
  },
  {
    label: "Phone No.",
    placeholder: "Phone No.",
    wrapperClass: "col-md-4",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "Phone",
    inputType: "number",
    className: "w100",
  },
  {
    label: "CNIC/ POR No.",
    placeholder: "CNIC/ POR No.",
    type: "number",
    name: "CNIC",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-4",
  },
  {
    label: "Province",
    placeholder: "Enter Province",
    name: "ProvinceId",
    id: "ProvinceId",
    inputType: "async-dropdown",
    url: "/province/GetAll?DisablePagination=true",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    inResult: true,
  },
  {
    label: "District",
    placeholder: "Enter District",
    name: "DistrictId",
    id: "DistrictId ",
    inputType: "async-dropdown",
    url: "/District/GetAll?DisablePagination=true",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "province",
      paramField: "ProvinceId",
      action: "useValue",
    },
    inResult: true,
  },
  {
    label: "Tehsil",
    placeholder: "Add Tehsil",
    name: "TehsilId",
    id: "TehsilId",
    inResult: true,
    inputType: "async-dropdown",
    url: "/Tehsil/GetAll?DisablePagination=true",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "District",
      paramField: "DistrictId",
      action: "useValue",
    },
  },
  {
    label: "Union Council",
    placeholder: "Add Union Council",
    name: "UCId",
    id: "UCId",
    inResult: true,
    inputType: "async-dropdown",
    url: "/UnionCouncil/GetAll?DisablePagination=true",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "Tehsil",
      paramField: "TehsilId",
      action: "useValue",
    },
  },
  {
    inputType: "h4",
    children: "",
    inForm: true,
    wrapperClass: "col-12",
  },

  {
    inputType: "button",
    buttonType: "submit",
    children: "Search",
    inSearch: true,
    wrapperClass: "btn-wrapper col-md-4 position-left  mt-2",
  },
];
