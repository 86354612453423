import React, { useEffect } from "react";
import "./AgeandGenderView.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";

const CurrentProjectsView = () => {
  const { projectPercentageData } = useSelector(
    (state) => state.records.current
  );
  const projectsList = projectPercentageData?.data?.items ?? [];
  console.log("🚀 ~ CurrentProjectsView ~ projectsList:", projectsList)
 

  return (
    <div className="bg-white shadow-sm progressContainer h-100">
      <Label>Current Projects</Label>
      <div className="d-flex flex-column overflow-auto hide-scrollbar">
        {projectsList.map((data, index) => {
          return (
            <div className="d-flex flex-column mb-3" key={data.projectId}>
              <div className="d-flex justify-content-between">
                <p class="lh-1 mb-1 heading">{data?.projectTitle}</p>
                <p class="lh-1 mb-1 heading">
                  {parseFloat(data?.completionPercentage).toFixed(0) > 100 ? 100 : parseFloat(data?.completionPercentage).toFixed(0)   }%
                </p>
              </div>
              <div
                class="progress"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="progress-bar"
                  style={{
                    width: `${data?.completionPercentage > 100 ? 100 : data.completionPercentage }%`,
                    background: "#84E0BE",
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CurrentProjectsView;
