import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, getRecords } from "../../redux/reducers";
import Form from "../form/Form";
import Loader from "../loader/loader";

const DashboardFilters = ({
  formFields = [],
  url = [],
  showDropdown, 
  handleClose 
}) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.records);

  const handleClear = () => {
    dispatch({ type: clearForm.type });
    url.map((item) => {
        dispatch({
          type: getRecords.type,
          payload: { url: item?.url , appendCurrentReducerKey: item?.appendCurrentReducerKey},
        });
      });
  };

  const handleSearch = (params = null) => {
    let newParams = {};

    if (params)
      Object.entries(params).forEach(([key, item]) => {
        newParams = {
          ...newParams,
          [key]:
            typeof item?.value === "object" ? item?.value.value : item?.value,
        };
      });

    // dispatch({
    //   type: getRecords.type,
    //   payload: { params: newParams, url },
    // });

    url.map((item) => {
      dispatch({
        type: getRecords.type,
        payload: { params: newParams, url: item?.url , appendCurrentReducerKey: item?.appendCurrentReducerKey},
      });
    });
  };

  return (
    <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
      <Loader loading={loading} />
     
        <h6 className="py-2 mb-0 mt-1 border-bottom">Filter By:</h6>
        <Form
          formData={formFields}
          onSubmit={(params) => handleSearch(params)}
          extraInputClass=""
        />
     
        <div className="mt-2 d-flex flex-row gap-1">
        <button
          onClick={handleClear}
          type="button"
          className="btn btn-danger col-md-6"
        >
          Clear
        </button>
        <button
          onClick={handleClose}
          type="button"
          className="btn btn-secondary  col-md-6"
        >
          Close
        </button>
        </div>
      </div>
  );
};

export default DashboardFilters;
