import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import { getUserName } from "../../utils/util";

const userName = getUserName();

export const generateTableRows = (data) => {
  return data.map((item, index) => {
    return new TableRow({
      children: [
        // Column 1: Project Name
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${item?.projectTitle ?? "-  "}  `,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        // Column 2: Thematic Area Beneficiaries
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Child Protection: ${
                    item?.thematicAreas[0]?.totalBeneficiaries ?? 0
                  }`,
                }),
                new TextRun({
                  text: `\nFood Security: ${
                    item?.thematicAreas[1]?.totalBeneficiaries ?? 0
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `\nWASH: ${
                    item?.thematicAreas[2]?.totalBeneficiaries ?? 0
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `\nShelter: ${
                    item?.thematicAreas[3]?.totalBeneficiaries ?? 0
                  }`,
                  break: 1,
                }),
              ],
            }),
          ],
        }),
        // Column 3: People with Disabilities (PWD)
        new TableCell({
          width: { size: 10, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Male: ${item?.pwdMaleCount ?? 0}`,
                }),
                new TextRun({
                  text: `\nFemale: ${item?.pwdFemaleCount ?? 0}`,
                  break: 1,
                }),
                new TextRun({
                  text: `\nTotal: ${
                    (item?.pwdMaleCount ?? 0) + (item?.pwdFemaleCount ?? 0)
                  }`,
                  break: 1,
                }),
              ],
            }),
          ],
        }),
        // Column 4: Status Counts
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `\n${item?.status[0]?.statusName + ":" ?? ""} ${
                    item?.status[0]?.totalBeneficiaries ?? 0
                  }`,
                }),
                new TextRun({
                  text: `\n${item?.status[1]?.statusName + ":" ?? ""} ${
                    item?.status[1]?.totalBeneficiaries ?? 0
                  }`,

                  break: 1,
                }),
                new TextRun({
                  text: `\n${item?.status[2]?.statusName + ":" ?? ""} ${
                    item?.status[2]?.totalBeneficiaries ?? 0
                  }`,

                  break: 1,
                }),
                new TextRun({
                  text: `\n${item?.status[3]?.statusName + ":" ?? ""} ${
                    item?.status[3]?.totalBeneficiaries ?? 0
                  }`,

                  break: 1,
                }),
                new TextRun({
                  text: `\n${item?.status[4]?.statusName + ":" ?? ""} ${
                    item?.status[4]?.totalBeneficiaries ?? 0
                  }`,

                  break: 1,
                }),
              ],
            }),
          ],
        }),
        // Column 5: Age Group Totals
        new TableCell({
          width: { size: 30, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `0-17 Years (Boys): ${
                    item?.ageGroups?.ageGroup_0_17?.boys ?? 0
                  }`,
                }),
                new TextRun({
                  text: `\n0-17 Years (Girls): ${
                    item?.ageGroups?.ageGroup_0_17?.girls ?? 0
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `\n18-25 Years (Male): ${
                    item?.ageGroups?.ageGroup_18_25?.youthMale ?? 0
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `\n18-25 Years (Female): ${
                    item?.ageGroups?.ageGroup_18_25?.youthFemale ?? 0
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `\n26+ Years (Men): ${
                    item?.ageGroups?.ageGroup_26Plus?.men ?? 0
                  }`,
                  break: 1,
                }),
                new TextRun({
                  text: `\n26+ Years (Women): ${
                    item?.ageGroups?.ageGroup_26Plus?.women ?? 0
                  }`,
                  break: 1,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  });
};

// Function to create and save the document
export const createReport = (items) => {
  const doc = new Document({
    title: "Beneficiary Report",
    creator: `${userName ?? "SIF"}`,
    sections: [
      {
        children: [
          // Report Title
          new Paragraph({
            children: [
              new TextRun({
                text: "Project Beneficiary Report",
                bold: true,
                size: 36,
              }),
            ],
            spacing: { after: 300 },
          }),
          // Table with all the fields
          new Table({
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: [
              // Header Row
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({ text: "Project Name", bold: true }),
                    ],
                    shading: { fill: "D3D3D3" },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({ text: "Thematic Areas", bold: true }),
                    ],
                    shading: { fill: "D3D3D3" },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({ text: "PWD Count", bold: true }),
                    ],
                    shading: { fill: "D3D3D3" },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({ text: "Status Counts", bold: true }),
                    ],
                    shading: { fill: "D3D3D3" },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({ text: "Age Groups", bold: true }),
                    ],
                    shading: { fill: "D3D3D3" },
                  }),
                ],
              }),
              // Dynamic Rows
              ...generateTableRows(items),
            ],
          }),
        ],
      },
    ],
  });

  // Save the document as a file
  Packer.toBlob(doc).then((blob) => {
    console.log("Report Generated Successfully");
    saveAs(blob, "Project_Beneficiary_Report.docx");
  });
};
