import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./Beneficiaries.scss";
import Table from "../../components/table/Table";
import BeneficiaryColumns from "../../assets/columns/BeneficiaryColumns";
import Loader from "../../components/loader/loader";
import { clearForm, createRecord, getRecords } from "../../redux/reducers";
import DeleteRow from "../DeleteRow";
import { ORDER_DIRECTION, USER_ROLE } from "../../Constants";
import SearchList from "../../components/search-list/SearchList";
import ListHeader from "../../components/list-header/ListHeader";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import FilterAccordian from "../../components/accordian/FilterAccordian";
import { BeneficiaryFilterFields } from "../../assets/fields/BeneficiaryFilterFields";
import { Modal } from "react-bootstrap";
import MdIcon from "../../components/icon/MdIcon";
import Form from "../../components/form/Form";
import { canView } from "../../utils/util";
import { FaArrowCircleLeft } from "react-icons/fa";
import { importBeneficiariesFields } from "../../assets/fields/importBeneficiariesFields";
import { confirmAlert } from "react-confirm-alert";

const Beneficiaries = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, created } = useSelector((state) => state.records);
  const { current } = useSelector((state) => state.records);

  const selectedBeneficiaryType = useSelector(
    (state) => state.form.values?.beneficiaryType?.value?.value || 0
  );

  const exportProjectBeneficiaries =current?.exportProjectBeneficiariesData?.data?.items;

  const [modalFields, setModalFields] = useState(
    getFieldsByCategory(importBeneficiariesFields, "beneficiary")
  );

  const [importType, setImportType] = useState("Beneficiary");

  const [typeSelector, setTypeSelector] = useState(0);

  const projectBeneficiariesData =
    current?.projectBeneficiariesData?.data?.items;


  const formValues = useSelector((state) => state.form.values);
  const formLookUps = useSelector((state) => state.form.lookups) ?? [];

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);
  const [searchFilterIsOpen, setSearchFilterIsOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (projectBeneficiariesData) {
      setSelectedRows(projectBeneficiariesData);
    }
  }, [projectBeneficiariesData]);

  useEffect(() => {
    getBeneficiaries();
  }, [created]);

  useEffect(() => {
    setMeta({ title: "Target Beneficiaries" });
  }, []);

  useEffect(() => {
  

    if (selectedBeneficiaryType == 0) {
      setModalFields(
        getFieldsByCategory(importBeneficiariesFields, "beneficiary")
      );
      setImportType("Beneficiary");
    }
    if (selectedBeneficiaryType == 1) {
      setModalFields(
        getFieldsByCategory(importBeneficiariesFields, "projectBeneficiary")
      );
      setImportType(`ProjectBeneficiary`);
    } else if (selectedBeneficiaryType == 2) {
      setModalFields(
        getFieldsByCategory(
          importBeneficiariesFields,
          "subActivityBeneficiary"
        ) ?? []
      );
      setImportType("SubActivity");
    }
  }, [selectedBeneficiaryType]);

  const getBeneficiaries = () => {
    const beneficiariesListUrl = "/Beneficiary/GetAll";
   
    if (id) {
      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/ProjectBeneficiary/GetBeneficiaries?ProjectId=${id}`,
          appendCurrentReducerKey: "projectBeneficiariesData",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });

      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/Dashboard/GetAllBeneficiariesDetails?Project.Id=1=${id}`,
          appendCurrentReducerKey: "exportProjectBeneficiariesData",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });
    } else {
      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/Beneficiary/GetAll`,
          appendCurrentReducerKey: "projectBeneficiariesData",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });

      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/Dashboard/GetAllBeneficiariesDetails`,
          appendCurrentReducerKey: "exportProjectBeneficiariesData",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });
    }
  };


  const downloadXLS =()=>{ 
    const XLSX = window.XLSX;
    const ws = XLSX.utils.json_to_sheet([...exportProjectBeneficiaries]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "report");
    XLSX.writeFile(wb, "records.xlsx");
  }


  const columns = [
    ...BeneficiaryColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          {/* <ToolTip label="Details">
            <Link to={`${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip> */}
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          <DeleteRow row={row} url="/Beneficiary" />
        </>
      ),
    },
  ];



  const fromatedData = function () {
    return projectBeneficiariesData;
  };

  const handleRowSelected = useCallback(({ selectedRows }) => {
    setSelectedRows((prevSelectedRows) => {
      if (JSON.stringify(prevSelectedRows) !== JSON.stringify(selectedRows)) {
        return selectedRows;
      }
      return prevSelectedRows;
    });
  }, []);

  const onUpdateProjectBeneficiaries = () => {
    const beneficiaryIds = selectedRows.map(({ id }) => id);
    dispatch({
      type: createRecord.type,
      payload: {
        beneficiaryIds: { value: beneficiaryIds },
        projectId: { value: id },
        url: "/ProjectBeneficiary",
      },
    });
  };


  const onDeleteProjectBeneficiaries = () => {
    // const beneficiaryIds = selectedRows.map(({ id }) => id);

    confirmAlert({
      message: "Are you sure to delete all project beneficiaries ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    });


   
  };


  const handleDelete = (Id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        // beneficiaryIds: { value: beneficiaryIds },
        projectId: { value: Id },
        url: "/Beneficiary/DeleteProjectBeneficiaries/?projectId="+Id,
      },
    });
  }

  const toggleModel = () => {
    setModalShow(!modalShow);
    dispatch({ type: clearForm.type });
    setTypeSelector(0);
  };

  const handleBulkData = (params = null) => {
    let newParams = {};
    let url = "";
    let selectedProject = formValues?.projectId?.value?.value ?? null;
    let selectedSubActivityId = formValues?.subActivityId?.value?.value ?? null;

    if (selectedBeneficiaryType == 0) {
      url = `/Beneficiary/ImportBeneficiaries?type=${importType}`;
    } else if (selectedBeneficiaryType == 1) {
      url = `/Beneficiary/ImportBeneficiaries?type=${importType}&projectId=${selectedProject}`;
    } else if (selectedBeneficiaryType == 2) {
      url = `/Beneficiary/ImportBeneficiaries?type=${importType}&projectId=${selectedProject}&subActivityId=${selectedSubActivityId}`;
    }

    if (params)
      Object.entries(params).forEach(([key, item]) => {
        newParams = {
          ...newParams,
          [key]:
            typeof item?.value === "object" ? item?.value.value : item?.value,
        };
      });

    dispatch({
      type: createRecord.type,
      payload: {
        ...formValues,
        url,
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
    setModalShow(false);
  };
  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }
  return (
    <div className="beneficiaries">
      <div className="d-md-flex py-2  justify-content-between align-items-center border-bottom ">
        <SearchList
         url={
          id
            ? `/ProjectBeneficiary/GetBeneficiaries?ProjectId=${id}`
            : "/Beneficiary/GetAll"
        }
          params={{ DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC }}
          searchFieldName="Name"
          appendCurrentReducerKey={"projectBeneficiariesData"}
          placeholder="Search by name"
          className="w-25"
        />
        <div className="d-flex mt-2">
          {canView([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN], userRoles) && (
            <div className="mr-2 ">
              <ToolTip label="Upload Bulk Data">
                <button
                  className="btn btn-light border border-primary ml-auto d-flex mb-2 align-items-center mr-2"
                  onClick={() => toggleModel()}
                >
                  <Icon.CloudUpload size={20} fill="blue" />
                  <span className="ml-2 text-primary">Upload Bulk Data</span>
                </button>
              </ToolTip>
            </div>
          )}

          <div className="mr-2 ">
            <ToolTip label="Filter">
              <button
                className="btn btn-primary ml-auto d-flex mb-2 align-items-center mr-2"
                onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
              >
                <Icon.Funnel size={20} />
                <span className="ml-2">Filter</span>
              </button>
            </ToolTip>
          </div>
          {!id ? (
            <div className="text-right">
              <ListHeader
                options={[
                  {
                    id: 1,
                    name: "PlusCircle",
                    url: "/beneficiaries/add",
                    itemClassName:
                      "btn btn-success d-flex align-items-center justify-content-center",
                    iconColor: "#fff",
                    label: "Add Benficiary",
                    text: "Add Benficiary",
                  },
                ]}
              ></ListHeader>
            </div>
          ) : (
          
           <div className="d-md-flex justify-content-between">
          <div className="px-2">
         <ToolTip label="Update Project Beneficiaries">
         <button
              onClick={onUpdateProjectBeneficiaries}
              type="button"
              className="btn btn-primary text-light mb-2"
            >
              <Icon.Pencil size={20} fill="white" />
              <span className="ml-2 text-white">Update Project Beneficiaries</span>
            </button>
         </ToolTip>
          </div>

            {canView([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN], userRoles) && (
            <div className="px-2 ">
              <ToolTip label="Delete All Project Beneficiaries">
                <button
                  className="btn btn-danger border border-danger ml-auto d-flex mb-2 align-items-center mr-2"
                  onClick={onDeleteProjectBeneficiaries}
                >
                  <Icon.Trash size={20} fill="white" />
                  <span className="ml-2 text-white">Delete Project Beneficiaries</span>
                </button>
              </ToolTip>
            </div>
          )}
           </div>

          
          )}
        </div>
      </div>

      <FilterAccordian
        formFields={BeneficiaryFilterFields}
        url={
          id
            ? `/ProjectBeneficiary/GetBeneficiaries?ProjectId=${id}&DisablePagination=true`
            : "/Beneficiary/GetAll?DisablePagination=true"
        }
        toggleFilter={searchFilterIsOpen}
        appendCurrentReducerKey={"projectBeneficiariesData"}
      />

      <button
        onClick={() => {
          navigate(-1);
        }}
        className=" bg-transparent text-secondary border-0 "
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span>
          <FaArrowCircleLeft style={{ fontSize: "20px" }} />
        </span>
        <span
          style={{
            textDecoration: "underline",
            marginTop: "4px",
            padding: "5px",
          }}
        >
          Go Back
        </span>
      </button>

      <div className="beneficiaryTable">
        <Loader loading={loading} />

        <Table
          columns={id ? BeneficiaryColumns : columns}
          data={fromatedData() ?? []}
          selectableRows={id ? true : false}
          onSelectedRowsChange={handleRowSelected}
          selectableRowSelected={rowSelectCritera}
          // canExport={true}
        />
        <div className="d-flex justify-content-end px-5 py-2">
        <button onClick={() => downloadXLS()} className="btn btn-info px-5 py-2">Export Data</button>
        </div>
       
      </div>

      {/* Bulk Data Modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="w-100 d-flex justify-content-between"
          >
            <div>Upload Bulk Data</div>
            <div>
              <MdIcon
                iconName={"MdOutlineClose"}
                action={() => setModalShow(!modalShow)}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <Form
            formData={[
              {
                label: "Beneficiary Type",
                placeholder: "Select Type",
                name: "beneficiaryType",
                inputType: "async-dropdown",
                options: [
                  { label: "Beneficiaries", value: 0 },
                  { label: "Project Beneficiaries", value: 1 },
                  { label: "Sub Activity Beneficiaries", value: 2 },
                ],
                searchAttrib: "label",
                id: "beneficiaryType",
                labelAttribute: "label",
                valueAttribute: "value",
                onChangeMethod: "asyncdropdown",
                inForm: false,
                wrapperClass: "col-md-12",
                isMulti: false,
              },
            ]}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />

          <Form
            formData={modalFields ?? []}
            withValidation={false}
            formValidation={[]}
            onSubmit={(params) => handleBulkData(params)}
            extraInputClass=""
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const rowSelectCritera = (row) => {
  return row.isAdded === true;
};

export default Beneficiaries;
