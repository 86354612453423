export const importBeneficiariesFields = [
  {
    category: "projectBeneficiary",
    label: "Project",
    placeholder: "Select Project",
    name: "projectId",
    inputType: "async-dropdown",
    url: "/project/GetAll?DisablePagination=true",
    searchAttrib: "title",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-12",
    isMulti: false,
  },

  {
    category: "subActivityBeneficiary",
    label: "Project",
    placeholder: "Select Project",
    name: "projectId",
    inputType: "async-dropdown",
    url: "/project/GetAll?DisablePagination=true",
    searchAttrib: "title",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-12",
    isMulti: false,
  },

  {
    category: "subActivityBeneficiary",
    label: "Select Sub Activity",
    placeholder: "Select Sub Activity",
    name: "subActivityId",
    inputType: "async-dropdown",
    url: "/SubActivity/GetSubActivitiesByProject?DisablePagination=true",
    searchAttrib: "name",
    id: "subActivityId",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-12",
    isMulti: false,
    condition: {
      fieldName: "projectId",
      paramField: "projectId",
      action: "useValue",
    },
  },

  {
    category: "subActivityBeneficiary",
    label: "Upload File",
    inputType: "file",
    inForm: true,
    onChangeMethod: "onFileChange",
    name: "attachment.Attachment",
    id: "Attachment",
    className: "mx-auto",
    wrapperClass: "col-12",
  },

  {
    category: "projectBeneficiary",
    label: "Upload File",
    inputType: "file",
    inForm: true,
    onChangeMethod: "onFileChange",
    name: "attachment.Attachment",
    id: "Attachment",
    className: "mx-auto",
    wrapperClass: "col-12",
  },

  {
    category: "beneficiary",
    label: "Upload File",
    inputType: "file",
    inForm: true,
    onChangeMethod: "onFileChange",
    name: "attachment.Attachment",
    id: "Attachment",
    className: "mx-auto",
    wrapperClass: "col-12",
  },
  {
   category: "beneficiary",
    inputType: "button",
    buttonType: "submit",
    children: "Save",
    inForm: true,
    wrapperClass: "pr-3 my-3 btn-wrapper col-12",
  },

  {
    category: "projectBeneficiary",
     inputType: "button",
     buttonType: "submit",
     children: "Save",
     inForm: true,
     wrapperClass: "pr-3 my-3 btn-wrapper col-12",
   },

  {
    category: "subActivityBeneficiary",
     inputType: "button",
     buttonType: "submit",
     children: "Save",
     inForm: true,
     wrapperClass: "pr-3 my-3 btn-wrapper col-12",
   },
];
