import React, { useEffect, useState } from "react";
import Form from "../form/Form";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, getRecords, resetData } from "../../redux/reducers";
import "bootstrap/dist/css/bootstrap.min.css";
import { pdf } from "@react-pdf/renderer";
import { exportFields } from "../../assets/fields/exportReportFields";
import BeneficiariesReportPDF from "../reports/BeneficiariesReportPDF";
import Loader from "../loader/loader";
import { createReport, generateDocx } from "../reports/BeneficairiesReportWord";
import { generateExcel } from "../reports/BeneficiaryReportExcel";

const ExportData = ({ showDropdown, handleClose }) => {
  const dispatch = useDispatch();

  const {
    projectWiseReport = [],
    sectorWiseReport = [],
    beneficiaryStatusReport = [],
  } = useSelector((state) => state?.records?.current ?? {});

  console.log(
    "🚀 ~ ExportData ~ beneficiaryStatusReport:",
    beneficiaryStatusReport
  );

  const reportFormat = useSelector(
    (state) => state?.form?.values?.reportFormate?.value?.value ?? {}
  );

  const { loading } = useSelector((state) => state.records);
  // const [loading, setLoading] = useState(false);

  const [allData, setAllData] = useState([]);
  console.log("🚀 ~ ExportData ~ allData:", allData);
  // Loading state

  useEffect(() => {
    if (
      projectWiseReport?.data?.items &&
      sectorWiseReport?.data?.items &&
      beneficiaryStatusReport?.data?.items
    ) {
      const combinedData = combineReports(
        projectWiseReport,
        sectorWiseReport,
        beneficiaryStatusReport
      );
      setAllData(combinedData);
    }
  }, [projectWiseReport, sectorWiseReport, beneficiaryStatusReport]);

  const handleClear = () => {
    dispatch({ type: clearForm.type });
    dispatch({ type: resetData.type });
    setAllData([]);
  };

  const handleSearch = async (params = null) => {
    // setLoading(true); // Set loading to true

    let newParams = {};
    if (params)
      Object.entries(params).forEach(([key, item]) => {
        newParams = {
          ...newParams,
          [key]:
            typeof item?.value === "object" ? item?.value.value : item?.value,
        };
      });

    await Promise.all([
      dispatch({
        type: getRecords.type,

        payload: {
          params: newParams,
          url: "/Dashboard/GetReportProjectWise",
          appendCurrentReducerKey: "projectWiseReport",
        },
      }),

      dispatch({
        type: getRecords.type,
        payload: {
          params: newParams,
          url: "/Dashboard/GetReportSectorWise",
          appendCurrentReducerKey: "sectorWiseReport",
        },
      }),

      dispatch({
        type: getRecords.type,
        payload: {
          params: newParams,
          url: "/Dashboard/GetReportBeneficiaryStatus",
          appendCurrentReducerKey: "beneficiaryStatusReport",
        },
      }),
    ]);

    const combinedData = combineReports(
      projectWiseReport,
      sectorWiseReport,
      beneficiaryStatusReport
    );
    setAllData(combinedData);
    // setLoading(false); // Set loading to false
  };

  // // Function to combine projectWiseReport and sectorWiseReport
  const combineReports = (projectWiseReport, sectorWiseReport, statusWiseReport) => {


    if (!Array.isArray(sectorWiseReport.data?.items)) {
      console.error("sectorWiseReport is not valid:", sectorWiseReport);
      return projectWiseReport.data?.items || []; // Return projectWiseReport as is if sectorWiseReport is not valid
    }

    if (!Array.isArray(statusWiseReport?.data?.items)) {
      console.error("statusWiseReport is not valid:", statusWiseReport);
      return projectWiseReport.data?.items || []; // Return projectWiseReport as is if sectorWiseReport is not valid
    }

    const sectorMap = sectorWiseReport.data.items.reduce((acc, sector) => {
      acc[sector.projectId] = sector.thematicAreas?.map((area) => ({
        thematicAreaDescription: area.thematicAreaDescription,
        totalBeneficiaries: area.totalBeneficiaries,
      }));
      return acc;
    }, {});

    // const statusMap = statusWiseReport.data.items.reduce((acc, statusData) => {
    //   acc[statusData.projectId] = statusData?.map((dataItem) => ({
    //     statusName: dataItem.status,
    //     totalBeneficiaries: dataItem.totalBeneficiaries,
    //   }));
    //   return acc;
    // }, {});


    // const statusMap = statusWiseReport?.data?.items.reduce(
    //   (acc, statusData) => {
    //     acc[statusData.projectId] =
    //       {
    //         statusName: statusData.status,
    //         totalBeneficiaries: statusData.totalBeneficiaries,
    //       }; // Assuming `beneficiaryStatus` is the field in the beneficiary report
    //     return acc;
    //   },
    //   {}
    // );


    const statusMap = statusWiseReport.data.items.reduce((acc, statusData) => {
      if (!acc[statusData.projectId]) {
        acc[statusData.projectId] = []; // Initialize as an array
      }
      acc[statusData.projectId].push({
        statusName: statusData.status,
        totalBeneficiaries: statusData.totalBeneficiaries,
      });
      return acc;
    }, {});

    return projectWiseReport.data?.items?.map((project) => {
      const thematicAreas = sectorMap[project.projectId] || [];
      const status = statusMap[project.projectId] || [];
      return {
        ...project,
        thematicAreas, // Add thematic areas from sectorWiseReport
        status,
      };
    });
  };

  // const combineReports = (
  //   projectWiseReport,
  //   sectorWiseReport,
  //   statusWiseReport
  // ) => {
  //   if (!Array.isArray(sectorWiseReport?.data?.items)) {
  //     console.error("sectorWiseReport is not valid:", sectorWiseReport);
  //     return projectWiseReport.data?.items || []; // Return projectWiseReport as is if sectorWiseReport is not valid
  //   }

  //   if (!Array.isArray(beneficiaryStatusReport?.data?.items)) {
  //     console.error(
  //       "beneficiaryStatusReport is not valid:",
  //       beneficiaryStatusReport
  //     );
  //     return projectWiseReport.data?.items || []; // Return projectWiseReport as is if beneficiaryStatusReport is not valid
  //   }

  //   // Map sector data by projectId
  //   const sectorMap = sectorWiseReport?.data?.items.reduce((acc, sector) => {
  //     acc[sector.projectId] = sector.thematicAreas?.map((area) => ({
  //       thematicAreaDescription: area.thematicAreaDescription,
  //       totalBeneficiaries: area.totalBeneficiaries,
  //     }));
  //     return acc;
  //   }, {});

  //   // Map beneficiary status data by projectId
  //   const beneficiaryStatusMap = beneficiaryStatusReport?.data?.items.reduce(
  //     (acc, statusData) => {
  //       acc[statusData.projectId] =
  //         {
  //           statusName: statusData.status,
  //           totalBeneficiaries: statusData.totalBeneficiaries,
  //         } || {}; // Assuming `beneficiaryStatus` is the field in the beneficiary report
  //       return acc;
  //     },
  //     {}
  //   );

  //   // Merge data
  //   return projectWiseReport.data?.items?.map((project) => {
  //     const thematicAreas = sectorMap[project.projectId] || [];
  //     const beneficiaryStatus = beneficiaryStatusMap[project.projectId] || {};
  //     return {
  //       ...project,
  //       thematicAreas, // Add thematic areas from sectorWiseReport
  //       beneficiaryStatus, // Add beneficiary status from beneficiaryStatusReport
  //     };
  //   });
  // };

  const handleDownload = async () => {
    if (reportFormat === "PDF") {
      const blob = await pdf(
        <BeneficiariesReportPDF data={allData} />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "beneficiaries_report.pdf");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else if (reportFormat === "Excel") {
      generateExcel(allData);
    } else if (reportFormat === "Word") {
      // generateDocx(allData);
      createReport(allData);
    } else {
      console.log("none");
    }
  };
  return (
    <div className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
      <Loader loading={loading} />
      <Form
        formData={exportFields}
        onSubmit={(params) => handleSearch(params)}
        extraInputClass={""}
      />

      <div className="mt-2 d-flex flex-column gap-2">
        <div className="d-flex flex-row gap-3 justify-content-center align-items-center">
          {allData.length > 0 && (
            <button
              onClick={() => handleDownload()}
              type="button"
              className="btn btn-success mr-2 w-100"
              disabled={loading}
            >
              Download Document
            </button>
          )}
        </div>

        <div className="d-flex flex-row gap-3">
          <button
            onClick={handleClear}
            type="button"
            className="btn btn-danger  w-100"
          >
            Clear
          </button>
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-secondary  w-100"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExportData;
