import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const generateExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Beneficiaries Report");

  // Define common styles
  const headerStyle = {
    font: { bold: true, color: { argb: "FFFFFFFF" } },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FF4F81BD" } },
  };

  const sectionHeaderStyle = {
    font: { bold: true },
    alignment: { horizontal: "left", vertical: "middle" },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFD9EAD3" } },
  };

  const contentStyle = {
    alignment: { horizontal: "center", vertical: "middle" },
    font: { size: 12 },
  };

  const separatorStyle = {
    border: { top: { style: "thick", color: { argb: "FF000000" } } },
    fill:{
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "d3d3d3" }
    }
  };

  // Add Title
  sheet.addRow(["Beneficiaries Report"]);
  sheet.mergeCells("A1:K1");
  sheet.getRow(1).font = { bold: true, size: 16 };
  sheet.getRow(1).alignment = { horizontal: "center" };
  sheet.getRow(1).height = 25;

  // Add data for each project
  data.forEach((item, index) => {
    if (index > 0) {
      sheet.addRow([]); // Add blank row
      sheet.addRow(["","","","","","","","","","",""]).eachCell((cell) => {
        cell.style = separatorStyle;
      }); // Add separator
      sheet.addRow([]); // Add blank row

    }

    // Project Name
    const projectNameRow = sheet.addRow(["Project Name"]);
    projectNameRow.eachCell((cell) => {
      cell.style = sectionHeaderStyle;
    });
    const projectTitleRow = sheet.addRow([item.projectTitle]);
    projectTitleRow.eachCell((cell) => {
      cell.style = contentStyle;
    });
    projectTitleRow.height = 20;

    // Total Beneficiaries
    sheet.addRow([]);
    const totalBeneficiariesHeaderRow = sheet.addRow(["Total Beneficiaries"]);
    totalBeneficiariesHeaderRow.eachCell((cell) => {
      cell.style = sectionHeaderStyle;
    });
    const totalBeneficiariesRow = sheet.addRow([item.totalBeneficiaries]);
    totalBeneficiariesRow.eachCell((cell) => {
      cell.style = contentStyle;
    });
    totalBeneficiariesRow.height = 20;

    // Sectors
    sheet.addRow([]);
    const sectorsHeaderRow = sheet.addRow(["SECTORS"]);
    sectorsHeaderRow.eachCell((cell) => {
      cell.style = sectionHeaderStyle;
    });
    const sectorsRow = sheet.addRow(["", ...item.thematicAreas.map((area) => area.thematicAreaDescription)]);
    sectorsRow.eachCell((cell, colNumber) => {
      if (colNumber > 1) {
        cell.style = headerStyle;
      } else {
        cell.style = contentStyle;
      }
    });
    const beneficiariesRow = sheet.addRow(["", ...item.thematicAreas.map((area) => area.totalBeneficiaries)]);
    beneficiariesRow.eachCell((cell, colNumber) => {
      cell.style = contentStyle;
    });

    // People with Disabilities
    const pwdHeaderRow = sheet.addRow(["PEOPLE WITH DISABILITIES"]);
    pwdHeaderRow.eachCell((cell) => {
      cell.style = sectionHeaderStyle;
    });
    const pwdSubHeaderRow = sheet.addRow(["", "Male", "Female", "Total"]);
    pwdSubHeaderRow.eachCell((cell,colNumber) => {
      if (colNumber > 1) {
        cell.style = headerStyle;
      } else {
        cell.style = contentStyle;
      }
    });
    const pwdDataRow = sheet.addRow([
      "",
      item.pwdMaleCount,
      item.pwdFemaleCount,
      item.pwdMaleCount + item.pwdFemaleCount,
    ]);
    pwdDataRow.eachCell((cell) => {
      cell.style = contentStyle;
    });



    sheet.addRow([]);
    const statusHeaderRow = sheet.addRow(["Status"]);
    statusHeaderRow.eachCell((cell) => {
      cell.style = sectionHeaderStyle;
    });
    const statusRow = sheet.addRow(["", ...item.status.map((area) => area.statusName)]);
    statusRow.eachCell((cell, colNumber) => {
      if (colNumber > 1) {
        cell.style = headerStyle;
      } else {
        cell.style = contentStyle;
      }
    });
    const statusBeneficiariesRow = sheet.addRow(["", ...item.status.map((area) => area.totalBeneficiaries)]);
    statusBeneficiariesRow.eachCell((cell, colNumber) => {
      cell.style = contentStyle;
    });



    // Gender - Age Groups
    const ageGroupHeaderRow = sheet.addRow(["Gender - Age Groups"]);
    ageGroupHeaderRow.eachCell((cell) => {
      cell.style = sectionHeaderStyle;
    });
    const ageGroupSubHeaderRow = sheet.addRow([
      "Age Group",
      "Boys (0-17)",
      "Girls (0-17)",
      "Total Children",
      "Youth Male (18-25)",
      "Youth Female (18-25)",
      "Total Youth",
      "Women (26+)",
      "Men (26+)",
      "Total Adults",
      "Grand Total",
    ]);
    ageGroupSubHeaderRow.eachCell((cell) => {
      cell.style = headerStyle;
    });
    const ageGroupDataRow = sheet.addRow([
      "Beneficiaries",
      item.ageGroups?.ageGroup_0_17?.boys ?? 0,
      item.ageGroups?.ageGroup_0_17?.girls ?? 0,
      item.ageGroups?.ageGroup_0_17?.total ?? 0,
      item.ageGroups?.ageGroup_18_25?.youthMale ?? 0,
      item.ageGroups?.ageGroup_18_25?.youthFemale ?? 0,
      item.ageGroups?.ageGroup_18_25?.total ?? 0,
      item.ageGroups?.ageGroup_26Plus?.men ?? 0,
      item.ageGroups?.ageGroup_26Plus?.women ?? 0,
      item.ageGroups?.ageGroup_26Plus?.total ?? 0,
      item.totalBeneficiaries ?? 0,
    ]);
    ageGroupDataRow.eachCell((cell) => {
      cell.style = contentStyle;
    });
    ageGroupDataRow.height = 20;
  });

  // Adjust column widths
  sheet.columns = [
    { width: 20 },
    { width: 25 },
    { width: 25 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];

  // Generate file and download
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), "Beneficiaries_Report.xlsx");
};
